<template>
    <section>
        <Header />
        <div class="container">
            <main class="principal">
                <picture class="only-pc aos">
                    <source srcset="../assets/landing/background_principal_pc.webp 2020w,
                    ../assets/landing/background_principal_pc1450.webp 1450w,
                    ../assets/landing/background_principal_pc1210.webp 1210w,
                    ../assets/landing/background_principal_pc900.webp 900w,
                    ../assets/landing/background_principal_pc380.webp 380w" type="image/webp" sizes="48.6vw">
                    <img src="../assets/landing/background_principal_pc.webp" 
                     srcset="../assets/landing/background_principal_pc.webp 2020w,
                    ../assets/landing/background_principal_pc1450.webp 1450w,
                    ../assets/landing/background_principal_pc1210.webp 1210w,
                    ../assets/landing/background_principal_pc900.webp 900w,
                    ../assets/landing/background_principal_pc380.webp 380w"
                    sizes="48.6vw" alt="Frame decorativo" fetchpriority="high" loading="eager">
                </picture>
                <picture class="only-mov aos">
                    <source srcset="../assets/landing/background_principal_mov.webp 828w,
                    ../assets/landing/background_principal_mov-300.webp 300w,
                    ../assets/landing/background_principal_mov-760.webp 760w,
                    ../assets/landing/background_principal_mov-1070.webp 1070w,
                    ../assets/landing/background_principal_mov-1220.webp 1220w" sizes="100.22vw" type="image/webp">
                    <img src="../assets/landing/background_principal_mov.webp" 
                    srcset="../assets/landing/background_principal_mov.webp 828w,
                    ../assets/landing/background_principal_mov-300.webp 300w,
                    ../assets/landing/background_principal_mov-760.webp 760w,
                    ../assets/landing/background_principal_mov-1070.webp 1070w,
                    ../assets/landing/background_principal_mov-1220.webp 1220w"
                    sizes="100.22vw" alt="Frame decorativo" fetchpriority="high" loading="eager">
                </picture>
                <h1 class="aos">Servicio de <br> branding</h1>
                <p class="aos">Somos brand builders encargandos de brindarte la identidad perfecta para tu negocio que
                     se encuentra buscando éxito en el mercado.  
                    <br>
                    <br>
                    Nos enfocamos en diseñar una imagen visual impactante y formar una línea de comunicación efectiva 
                    para que puedas crear esa conexión con tu mercado objetivo. 
                </p>
            </main>
            <section class="medio">
                <div class="aos">
                    <h2 class="medio__titulo">¿En qué se basa nuestro servicio de Branding?</h2> <br>

                    <ul>

                        <li>
                            <p><strong>Identidad de Marca: </strong>Desarrollamos una identidad de marca única que
                                refleje tus valores y propósito, asegurando una conexión auténtica con tu audiencia.</p>
                        </li>
                        <li>
                            <p><strong>Diseño de Logo: </strong>Diseñamos logos que capturan la esencia de tu negocio y
                                dejan un recuerdo de marca.</p>
                        </li>
                        <li>
                            <p><strong>Diseño de Empaque: </strong>Creamos empaques atractivos y funcionales que
                                resaltan en el mercado.</p>
                        </li>
                        <li>
                            <p><strong>Estrategia de Comunicación: </strong> Formamos una estrategia de comunicación
                                clara y consistente para empatizar con tus clientes y fortalecer tu presencia de marca.
                            </p>
                        </li>
                    </ul>
                    <br>
                    <h2 class="medio__titulo">¿Por Qué Elegirnos?</h2> <br>
                    <ul>

                        <li>
                            <p><strong>Creatividad: </strong>Innovamos en cada proyecto para ofrecer soluciones únicas y
                                personalizadas.</p>
                        </li>
                        <li>
                            <p><strong>Dedicación: </strong>Nos comprometemos plenamente con cada cliente, asegurando
                                resultados excepcionales.</p>
                        </li>
                        <li>
                            <p><strong>Responsabilidad: </strong>Trabajamos con integridad y profesionalismo en cada
                                etapa del proceso de branding.</p>
                        </li>
                    </ul>
                    <p>Comencemos a trabajar en tu imagen, <br>
                        <a href="https://wa.link/6hddlt">contáctanos</a> y empieza a resaltar en el mercado.
                    </p> <br>
                </div>
                <div class="aos">
                    <h2 class="medio__titulo">Servicio de naming para empresas: </h2> <br>
                    <p>
                        Somos una agencia de naming, dedicada a crear nombres exitosos para negocios, con historias 
                        creativas y únicas como las que nos contaban antes de dormir. Estas historias nos ayudan a 
                        comprender la esencia y el origen de tu producto o servicio y a través de estas narrativas, 
                        encontraremos las palabras y frases que darán vida al nombre que inmortalizará tu marca.
                    </p> <br class="only-pc"><br class="only-pc">

                    <h2 class="medio__titulo">¿Por Qué es Importante el nombre para tu marca?</h2> <br>

                    <ul>
                        <li>
                            <p><strong>Identidad de Marca: </strong>Un nombre bien elegido establece una identidad
                                sólida y memorable para tu negocio.</p>
                        </li>
                        <li>
                            <p><strong>Conexión Emocional: </strong>Las historias detrás del nombre crean una conexión
                                emocional con tu audiencia.</p>
                        </li>
                        <li>
                            <p><strong>Diferenciación: </strong>Un nombre único y significativo te distingue de la
                                competencia.</p>
                        </li>
                    </ul>
                    <br>
                    <br>

                    <h2 class="medio__titulo">Conoce nuestro proceso de Naming</h2> <br>

                    <ul>
                        <li>
                            <p><strong>Investigación y Comprensión: </strong>Nos sumergimos en la historia de tu marca
                                para entender sus valores, propósito y visión.</p>
                        </li>
                        <li>
                            <p><strong>Creación de Historias: </strong>Desarrollamos narrativas que capturan la esencia
                                de tu negocio.</p>
                        </li>
                        <li>
                            <p><strong>Selección de Nombres: </strong>Identificamos nombres potenciales a partir de las
                                historias, asegurándonos de que sean únicos y relevantes.</p>
                        </li>
                        <li>
                            <p><strong>Validación y Prueba: </strong>Evaluamos los nombres seleccionados para asegurar
                                su impacto y viabilidad en el mercado.</p>
                        </li>
                    </ul>
                    <br>
                    <br>
                    <p>Transforma tu marca con un nombre que cuente su historia. </p> <br><br><br>

                    <a href="https://wa.link/bu7xil" class="cotizar__whatsapp">
                        <img src="../assets/landing/recurso_whatsapp.webp"
                            alt="Mándanos mensaje por whatsapp si te interesa el servicio de Branding">

                        <p>Contáctanos <br>
                            vía WhatsApp </p>
                    </a>
                </div>

                <div class="aos">
                    <h2 class="medio__titulo">¿Te interesa renovar o desarrollar
                        tu proyecto con nosotros?</h2> <br>
                    <p>
                        Cotiza aquí sin compromiso alguno.
                    </p> <br>
                </div>

                <form class="aos" @submit.prevent="contactoLanding()">
                    <label for="form__name">Nombre</label>
                    <input type="text" id="form__name" v-model="name">
                    <label for="form__email">Correo electrónico</label>
                    <input type="email" id="form__email" v-model="email">
                    <label for="form__phone">Teléfono</label>
                    <input type="number" v-model="phone">
                    <label for="form__message">Mensaje</label>
                    <textarea id="form__message" v-model="message"></textarea>

                    <button type="submit">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="only-pc">
                            <circle cx="24" cy="24" r="24" fill="#987EF3" />
                            <g clip-path="url(#clip0_137_399)">
                                <path
                                    d="M20.592 26.4954C20.4027 26.3414 20.2186 26.1848 20.0274 26.0399C18.3003 24.7329 16.5649 23.4382 14.8538 22.1116C14.6418 21.9478 14.5554 21.6188 14.4107 21.3669C14.6582 21.2636 14.9005 21.089 15.1558 21.0686C20.9931 20.5774 26.8324 20.0967 32.6719 19.6388C33.038 19.6102 33.4206 19.7784 33.796 19.855C33.6243 20.1794 33.5068 20.5536 33.273 20.8224C29.425 25.2399 25.5633 29.6462 21.6938 34.0434C21.5236 34.2358 21.2403 34.326 21.0093 34.4653C20.893 34.1991 20.6807 33.932 20.6759 33.6653C20.6347 31.5008 20.6337 29.3357 20.6171 27.1718C20.6153 26.9529 20.6011 26.7353 20.592 26.4954ZM31.0489 20.8108C31.0355 20.7584 31.0215 20.7081 31.0081 20.6558C26.0791 21.0602 21.1508 21.4659 16.2226 21.8716C17.6536 23.1375 19.115 24.2191 20.5455 25.3392C21.003 25.6977 21.3627 25.7561 21.8979 25.4782C24.7606 23.9967 27.6458 22.5629 30.523 21.1102C30.7038 21.0191 30.874 20.9103 31.0489 20.8108ZM31.4886 21.526C31.3506 21.4643 31.3252 21.4427 31.3123 21.4492C28.1515 23.03 24.99 24.6096 21.8352 26.2055C21.6905 26.2783 21.5217 26.4831 21.521 26.6295C21.5103 28.6332 21.5269 30.6395 21.5376 32.8818C24.9426 28.9959 28.1967 25.2828 31.4886 21.526Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_137_399">
                                    <rect width="18" height="14.6667" fill="white"
                                        transform="translate(14.4043 21.3701) rotate(-26.7378)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <p>
                            Enviar mensaje
                        </p>
                    </button>

                    <p class="form__status">{{ statusForm }}</p>
                </form>
            </section>
            <section class="proyectos">
                <h2 class="aos">Conoce algunos de <span>nuestros proyectos:</span></h2>
                <div class="proyectos__galeria only-pc">
                    <button class="aos" v-for="(project, index) in projects" :key="index"
                        @click="navAndDialog(project.link, project.imageWebp)" :aria-label="project.title">
                        <picture>
                            <source :srcset="project.imageWebp" type="image/webp">
                            <img :src="project.imagePng" :alt="project.title">
                        </picture>
                    </button>
                </div>
                <div class="proyectos__galeria only-mov">
                    <button class="aos" v-for="(project, index) in projectsmv" :key="index"
                        @click="navAndDialog(project.link, project.imageWebp)" :aria-label="project.title">
                        <picture>
                            <source :srcset="project.imageWebp" type="image/webp">
                            <img :src="project.imagePng" :alt="project.title">
                        </picture>
                    </button>
                </div>
                <h2 class="aos">Trabajemos juntos, <span>cotiza aquí.</span></h2>
            </section>
            <dialog @click="closeModal()">
                <img :src="imageSelected" alt="Imagen en galería">
            </dialog>
        </div>
        <Footer :datos-footer="datosFooter" :landingWeb="true"/>
    </section>
</template>
<script>
import Header from '../components/on-site/Header.vue';
import Footer from '../components/on-site/Footer.vue';
export default {
    components: {
        Header,
        Footer,
    },

    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            statusForm: '',
            imageSelected: null,
            projects: [
                {
                    imageWebp: require('../assets/landing/proyecto_7.webp'),
                    imagePng: require('../assets/landing/proyecto_7.png'),
                    link: false,
                    title: "Musho Cevishe"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_8.webp'),
                    imagePng: require('../assets/landing/proyecto_8.png'),
                    link: false,
                    title: "Holy O"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_9.webp'),
                    imagePng: require('../assets/landing/proyecto_9.png'),
                    link: false,
                    title: "Mappy"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_10.webp'),
                    imagePng: require('../assets/landing/proyecto_10.png'),
                    link: false,
                    title: "La Verbena"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_11.webp'),
                    imagePng: require('../assets/landing/proyecto_11.png'),
                    link: false,
                    title: "Baby Mo"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_12.webp'),
                    imagePng: require('../assets/landing/proyecto_12.png'),
                    link: false,
                    title: "Tribo"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_13.webp'),
                    imagePng: require('../assets/landing/proyecto_13.png'),
                    link: false,
                    title: "Legatus"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_14.webp'),
                    imagePng: require('../assets/landing/proyecto_14.png'),
                    link: false,
                    title: "Sweat & Wear"
                },
            ],
            projectsmv: [
            {
                    imageWebp: require('../assets/landing/proyecto_7_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_7_mv.png'),
                    link: false,
                    title: "Musho Cevishe"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_8_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_8_mv.png'),
                    link: false,
                    title: "Holy O"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_9_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_9_mv.png'),
                    link: false,
                    title: "Mappy"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_10_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_10_mv.png'),
                    link: false,
                    title: "La Verbena"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_11_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_11_mv.png'),
                    link: false,
                    title: "Baby Mo"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_12_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_12_mv.png'),
                    link: false,
                    title: "Tribo"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_13_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_13_mv.png'),
                    link: false,
                    title: "Legatus"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_14_mv.webp'),
                    imagePng: require('../assets/landing/proyecto_14_mv.png'),
                    link: false,
                    title: "Sweat & Wear"
                },
            ],
        }
    },

    mounted() {
        let animateOnScroll = () => {
            const allAos = document.querySelectorAll(".aos");

            let allHaveVisibleClass = true;

            allAos.forEach((elemento, index) => {
                let { top, height, bottom } = elemento.getBoundingClientRect();

                if (window.getComputedStyle(elemento).display === 'none'
                    || bottom > 0 && bottom < window.innerHeight
                    || height >= window.innerHeight && top <= 0) {
                    elemento.classList.add("visible");
                }

                if (!elemento.classList.contains("visible")) {
                    allHaveVisibleClass = false;
                }

            });

            if (allHaveVisibleClass) window.removeEventListener('scroll', animateOnScroll)

        }

        window.addEventListener('scroll', animateOnScroll);
        animateOnScroll()
    },

    methods: {
        async contactoLanding() {

            if (this.name === '' || this.name === null || this.name === undefined) {
                this.statusForm = "Campo Nombre no válido";
                return ''
            }

            if (this.email === '' || this.email === null || this.email === undefined) {
                this.statusForm = "Campo Correo Electrónico no válido";
                return ''
            }

            if (this.phone === '' || this.phone === null || this.phone === undefined) {
                this.statusForm = "Campo Teléfono no válido";
                return ''
            }

            if (this.message === '' || this.message === null || this.message === undefined) {
                this.statusForm = "Campo Mensaje no válido";
                return ''
            }

            let data = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                mensaje: this.message,
                from: "landing naming & branding",
            };

            let response = await this.$store.dispatch(
                "admin/enviarMailContacto",
                data
            );

            if (response.status === 'success') {
                this.limpieza();
            } else {
                this.statusForm = "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
            }
        },

        limpieza() {
            this.name = '';
            this.phone = '';
            this.email = '';
            this.message = '';

            this.statusForm = 'Mensaje enviado con éxito';

            setTimeout(() => {
                this.statusForm = '';
            }, 3000);
        },

        navAndDialog(link, image) {
            if (link) {
                window.location.href = link;
            } else {
                this.imageSelected = image;
                this.openModal();
            }
        },

        openModal() {
            const modal = document.querySelector("dialog");
            modal.showModal();
        },

        closeModal() {
            const modal = document.querySelector("dialog");
            this.imageSelected = null;
            modal.close();
        }
    },

    metaInfo: {
        title: 'Agencia de branding y naming',  
        meta: [
            {
                name: 'description',
                content: 'Construimos y diseñamos desde cero la identidad y personalidad perfecta para llevar al éxito tu marca.'

            }
        ]
    },

}
</script>
<style scoped>
*,
*::after,
*::before {
    box-sizing: border-box;
}

h2,
p {
    margin: 0;
}

section {
    overflow: clip;
}

picture {
    display: block;
}

picture img {
    width: inherit;
    height: inherit;
}

.principal {
    position: relative;
}

.principal picture {
    position: absolute;
    top: 0;
}

.principal h1 {
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 500;
    color: #EF67C2;
}

.principal p {
    font-family: "IBM Plex Mono";
    font-weight: 400;
    font-style: normal;
    color: #000;
}

.medio p {
    font-family: "IBM Plex Mono";
    font-weight: 400;
    font-style: normal;
    color: #000;
}

.medio p a {
    color: #987EF3;
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 400;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    text-align: center;
    text-decoration: underline;
}

.medio .medio__titulo {
    font-family: "IBM Plex Mono";
    font-weight: 700;
    font-style: normal;
    color: #000;
}


.medio>div:not(:last-of-type) {
    position: relative;
}

.medio>div::before {
    content: "";
    position: absolute;
    background-color: #FFB6EA;
}

.proyectos>h2:first-of-type {
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 500;
    color: #987EF3;
}

.proyectos>h2:first-of-type span {
    color: #FFB6EA;
}

.proyectos>h2:last-of-type {
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 500;
    color: #EF67C2;
}

.proyectos__galeria {
    display: grid;
}

.proyectos__galeria button picture {
    width: inherit;
    height: inherit;
}

.proyectos>h2:last-of-type span {
    color: #B2ACFC;
}

form {
    display: grid;
}

label,
input,
textarea {
    color: #000;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 500;
}

input {
    border: 0;
    padding: 0;
}

textarea {
    border: 0;
    resize: none;
    padding: 0;
}

button[type='submit'] {
    border: 0;
    display: grid;
    align-items: center;
    padding: 0;
    cursor: pointer;
}


button[type='submit'] p {
    font-family: Gramatika-Bold !important;
    font-style: normal !important;
    font-weight: 400 !important;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.proyectos__galeria button {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

dialog {
    padding: 0;
    border: 0;
    display: flex;
    background-color: transparent;
    justify-content: center;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.cotizar__whatsapp {
    display: block;
    width: fit-content;
}

.cotizar__whatsapp p {
    color: #987EF3;
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    text-align: center;
}


@media (width < 768px),
(max-width: 767px) {

    .aos {
        opacity: 0;
        transform: translate(0, var(--traslado, -16.908212560386474VW));
        transition: opacity var(--visible, 0.6s) ease-out, transform var(--visible, 0.6s) ease-out;
    }

    .visible {
        opacity: 1;
        transform: translate(0, 0) !important;
    }

    .only-pc {
        display: none;
    }

    .principal {
        background-color: #E4E4F9;
        padding: 41.78743961352657VW 10.869565217391305VW 4.1062801932367154VW 12.80193236714976VW;
    }

    .principal picture {
        --traslado: 0;
        width: 100%;
        left: 0;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
    }

    .principal h1 {
        font-size: 12.077294685990339VW;
        line-height: 109.3%;
        margin-right: 3.985507VW;
    }

    .principal p {
        margin: 17.874396135265698VW 0 0;
        font-size: 2.657004830917874VW;
        line-height: 180%;
        padding-right: 0.3125VW;
    }

    .cotizar__whatsapp p {
        font-size: 3.3816425120772946VW;
        line-height: 161.8%;
        text-underline-offset: 0.966183574879227VW;
    }

    .cotizar__whatsapp img {
        width: 25.60386473429952VW;
        height: 25.60386473429952VW;
    }

    .medio {
        padding: 153.444444VW 10.144927536231885VW 8.695652173913043VW 14.492753623188406VW;
        position: relative;
        background: 
            url("../assets/landing/background_medio_naming_mov.webp"),
            linear-gradient(to bottom, #E4E4F9 0, #FFFFFF 72.94685990338165VW);
        background-repeat: no-repeat;
        background-size: 100vw auto;
        background-position: top center;
    }

    .medio>div:nth-of-type(2) {
        margin: 21.73913043478261VW 0 21.980676328502415VW;
    }

    .medio>picture {
        width: 125%;
        position: absolute;
        top: 5.555555555555555VW;
        left: -15.217391304347828VW;
    }

    .medio>div::before {
        height: 0.4830917874396135VW;
        width: 10.869565217391305VW;
    }

    .medio>div:first-of-type::before {
        top: -9.420289855072465VW;
        left: 0;
    }

    .medio>div:nth-of-type(2)::before,
    .medio>div:nth-of-type(3)::before {
        top: -10.869565217391305VW;
        left: 0;
    }

    .medio p:not(.medio__titulo) {
        font-size: 2.657004830917874VW;
        line-height: 180%;
    }

    .medio__titulo {
        font-size: 3.3816425120772946VW;
        line-height: 180%;
    }

    .proyectos>h2:first-of-type {
        margin: 8.695652173913043VW 21.73913043478261VW 9.178743961352657VW 10.869565217391305VW;
        font-size: 10.869565217391305VW;
        line-height: 110.8%;
    }

    .proyectos__galeria {
        overflow: clip;
        width: 100vw;
        grid-template-columns: auto 1fr;
    }

    .proyectos__galeria button:nth-of-type(1) {
        margin: 0 0 5.314009661835748VW 14.492753623188406VW;
        width: 71.73913043478261VW;
        height: 79.22705314009661VW;
        aspect-ratio: 71.73913043478261 / 79.22705314009661;
        grid-row: 6 / 7;
        --traslado: 0;
        transform: translate(-100%, var(--traslado));
        grid-column: span 2;
        grid-row: 1 / 2;
    }

    .proyectos__galeria>button:nth-of-type(2) {
        margin: 0 0 5.797101449275362VW 7.246376811594203VW;
        width: 67.14975845410628VW;
        height: 73.67149758454107VW;
        aspect-ratio: 67.14975845410628 / 73.67149758454107;
        grid-row: 1 / 2;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
        grid-column: span 2;
        grid-row: 2 / 3;
    }

    .proyectos__galeria>button:nth-of-type(3) {
        margin: 0 0 7.246376811594203VW 7.246376811594203VW;
        width: 85.99033816425121VW;
        height: 69.56521739130434VW;
        aspect-ratio: 85.99033816425121 / 69.56521739130434;
        grid-row: 3 / 4;
        --traslado: 0;
        transform: translate(-100%, var(--traslado));
        grid-column: span 2;
        grid-row: 4 / 5;
    }

    .proyectos__galeria>button:nth-of-type(4) {
        margin: 0 0 7.246376811594203VW 19.32367149758454VW;
        width: 87.92270531400966VW;
        height: 85.02415458937197VW;
        aspect-ratio: 87.92270531400966 / 85.02415458937197;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
        grid-column: span 2;
        grid-row: 5 / 6;
    }

    .proyectos__galeria>button:nth-of-type(5) {
        margin: 0 0 7.246376811594203VW 31.15942028985507VW;
        width: 58.454106280193244VW;
        height: 58.454106280193244VW;
        aspect-ratio: 1;
        grid-row: 4 / 5;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
        grid-column: span 2;
        grid-row: 3 / 4;
    }

    .proyectos__galeria>button:nth-of-type(6) {
        margin: 0 0 0 -4.830917874396135VW;
        width: 59.42028985507246VW;
        height: 63.28502415458937VW;
        aspect-ratio: 59.42028985507246 / 63.28502415458937;
        grid-row: 2 / 3;
        --traslado: 0;
        transform: translate(-100%, var(--traslado));
        grid-row: 6 / 7;

    }

    .proyectos__galeria>button:nth-of-type(7) {
        margin: 0 0 0 16.666666666666664VW;
        width: 67.14975845410628VW;
        height: 73.67149758454107VW;
        aspect-ratio: 67.14975845410628 / 73.67149758454107;
        grid-row: 2 / 3;
        --traslado: 0;
        transform: translate(-100%, var(--traslado));
        grid-row: 7 / 8;
    }

    .proyectos__galeria>button:nth-of-type(8) {
        margin: 25.60386473429952VW 0 6.038647342995169VW -24.154589371980677VW;
        width: 59.42028985507246VW;
        height: 63.28502415458937VW;
        aspect-ratio: 59.42028985507246 / 63.28502415458937;
        grid-row: 2 / 3;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
        grid-row: 6 / 7;
    }

    .proyectos>p:last-of-type {
        margin: 23.42995169082126VW 21.497584541062803VW 36.95652173913043VW 10.869565217391305VW;
        font-size: 10.869565217391305VW;
        line-height: 110.8%;
    }


    label,
    input,
    textarea {
        font-size: 2.898550724637681VW;
        line-height: 246.8%;
    }


    input {
        border-bottom: 0.24154589371980675VW solid #000;
        margin-bottom: 12.077294685990339VW;
        height: 7.246376811594203VW;
    }

    textarea {
        border-bottom: 0.24154589371980675VW solid #000;
        height: 14.492753623188406VW;
    }

    button[type='submit'] {
        background-color: #3702FB;
        width: 27.053140096618357VW;
        height: 27.053140096618357VW;
        grid-template-columns: auto;
        margin: 17.632850241545896VW auto 0;
        border-radius: 100%;
        padding: 0 3.6231884057971016VW;
    }

    button[type='submit'] p {
        color: #FFFFFF !important;
        font-size: 3.140096618357488VW !important;
        line-height: 106.8% !important;
    }

    .form__status {
        margin: 4.830917874396135VW 0 0;
        height: 4.830917874396135VW;
        text-align: center;
    }

    dialog img {
        width: 100%;
    }
}

@media(width >=768px), (min-width: 768px) {

    .aos {
        opacity: 0;
        transform: translate(var(--traslado, 0, -5.208333333333334VW));
        transition: opacity var(--visible, 0.6s) ease-out, transform var(--visible, 0.6s) ease-out;
    }

    .visible {
        opacity: 1;
        transform: translate(0, 0);
    }

    .only-mov {
        display: none;
    }

    .principal {
        padding: 15.625VW 47.979167VW 8.447917VW 8.59375VW;
        background: linear-gradient(to bottom, #E4E4F9 54.166666666666664VW, #FFFFFF);
    }

    .principal picture {
        position: absolute;
        width: 51.40625VW;
        aspect-ratio: 1097/1118;
        right: -1vw;
    }

    .principal h1 {
        font-size: 7.552083333333333VW;
        line-height: 126%;
        margin: 0;
    }

    .principal p {
        margin: 3.0729166666666665VW 0 0;
        font-size: 0.8333333333333334VW;
        line-height: 180%;
    }

    .medio {
        padding: 7.5VW 11.458333333333332VW 9.739583333333333VW 51.87500000000001VW;
        background-size: 59.34375VW auto;
        background-position: left top;
        background-repeat: no-repeat;
        background-color: #FFF;
        background-image: url("../assets/landing/background_medio_naming.png");
        background-image: url("../assets/landing/background_medio_naming.webp");
    }

    .medio>div::before {
        height: 0.10416666666666667VW;
        width: 3.125VW;
    }

    .cotizar__whatsapp p {
        font-size: 0.9375VW;
        line-height: 161.8%;
        text-underline-offset: 0.20833333333333334VW;
    }

    .cotizar__whatsapp img {
        width: 8.6171875VW;
        height: 8.6171875VW;
    }

    .medio>div:nth-of-type(2) {
        margin: 5VW 0 4.322916666666667VW;
    }

    .medio>div:first-of-type::before {
        top: -1.7187500000000002VW;
        left: -4.84375VW;
    }

    .medio>div:nth-of-type(2)::before,
    .medio>div:nth-of-type(3)::before {
        top: -1.9791666666666665VW;
        left: -5.104166666666667VW;
    }

    .medio p:not(.medio__titulo) {
        font-size: 0.78125VW;
        line-height: 180%;
    }

    .medio__titulo {
        font-size: 1.0416666666666665VW;
        line-height: 180%;
    }

    .proyectos>h2:first-of-type {
        width: 26.041666666666668VW;
        margin: 4.739583333333333VW 0 6.666666666666667VW 9.635416666666668VW;

        font-size: 5.208333333333334VW;
        line-height: 104.3%;
    }

    .proyectos__galeria {
        grid-template-columns: repeat(3, auto);
        justify-content: center;
    }

    .proyectos__galeria button {
        overflow: clip;
    }

    .proyectos__galeria button picture {
        transition: 0.6s ease-out;
    }

    .proyectos__galeria button:hover picture {
        transform: scale(1.2);
    }

    .proyectos__galeria button:nth-of-type(1) {
        width: 28.854166666666664VW;
        height: 29.583333333333332VW;
        aspect-ratio: 28.854166666666664 / 29.583333333333332;
    }

    .proyectos__galeria>button:nth-of-type(2) {
        margin: 1.7708333333333333VW 0 0 2.2395833333333335VW;
        width: 24.0625VW;
        height: 24.0625VW;
        aspect-ratio: 1;
    }

    .proyectos__galeria>button:nth-of-type(3) {
        margin: 0 0 0 2.2395833333333335VW;
        width: 27.8125VW;
        height: 25.208333333333332VW;
        aspect-ratio: 27.8125 / 25.208333333333332;
    }

    .proyectos__galeria>button:nth-of-type(4) {
        margin: 3.125VW 0 0 1.5625VW;
        width: 22.5VW;
        height: 22.5VW;
        aspect-ratio: 1;
    }

    .proyectos__galeria>button:nth-of-type(5) {
        margin: 1.5625VW 0 0 -2.7083333333333335VW;
        width: 28.854166666666664VW;
        height: 29.583333333333332VW;
        aspect-ratio: 28.854166666666664 / 29.583333333333332;
    }

    .proyectos__galeria>button:nth-of-type(6) {
        margin: -1.6666666666666667VW 0 0 2.864583333333333VW;
        width: 27.8125VW;
        height: 24.791666666666668VW;
        aspect-ratio: 27.8125 / 24.791666666666668;
    }

    .proyectos__galeria>button:nth-of-type(7) {
        margin: -3.5416666666666665VW 0 0 -0.20833333333333334VW;
        width: 24.0625VW;
        height: 19.6875VW;
        aspect-ratio: 24.0625 / 19.6875;
    }

    .proyectos__galeria>button:nth-of-type(8) {
        margin: -5.3125VW 0 0 3.0729166666666665VW;
        width: 26.145833333333336VW;
        height: 19.6875VW;
        aspect-ratio: 26.145833333333336 / 19.6875;
        grid-column: 3 / 4;
    }

    .proyectos>h2:last-of-type {
        width: 50VW;
        margin: 9.583333333333334VW 0 12.135416666666666VW 9.583333333333334VW;

        font-size: 5.208333333333334VW;
        line-height: 104.3%;
    }

    form {
        --traslado: 100%, 0;
    }

    label,
    input,
    textarea {
        font-size: 0.7291666666666666VW;
        line-height: 246.8%;
    }


    input {
        border-bottom: 0.052083333333333336VW solid #000;
        margin-bottom: 2.864583333333333VW;
        height: 1.8229166666666667VW;
    }

    textarea {
        border-bottom: 0.052083333333333336VW solid #000;
        height: 4.166666666666666VW;
    }

    button[type='submit'] {
        background-color: transparent;
        color: #000;
        width: 9.835416666666668VW;
        grid-template-columns: auto 1fr;
        column-gap: 1.0416666666666665VW;
        margin: 3.4375000000000004VW 0 0;
    }

    button[type='submit'] p {
        font-size: 0.8333333333333334VW !important;
        line-height: 152.3% !important;
    }

    .form__status {
        margin: 1.0416666666666665VW 0 0;
        height: 1.5625VW;
    }

    dialog img {
        width: 60%;
        height: auto;
    }
}
</style>